import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Select, Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import '../styles/dashboardQuestion.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faEllipsisStrokeVertical, faAlignSlash, faArrowRotateRight, faTrashCan, faCode, faFolderOpen} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import DashboardQuestionFiles from "../dialogs/DashboardQuestionFiles";
import DashboardQuestionMenu from "../dialogs/DashboardQuestionMenu";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestion({width, configuration, Dialog, dialog, toast, system, locale, setModel, setFunctions, model, functions, text, setText, setToast, setMessages, Navigation, loading, setGrid, grid, messages, tabs, setSystem, setSnackbar}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [menu, setMenu] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const Message = () => {
        setToast({message: locale.dashboardQuestion.v, color: 'primary', display: 'center', duration: false});
        setMenu(false);

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'update',
            data: {
                text: text,
                model: model,
                functions: functions,
                files: selectedFiles,
                grid: !Empty(grid) ? grid : null
            }
        };
        wsCall(message);
    }

    const Text = data => {
        let item = data;
        if (!item) {
            item = '<p></p>'
        }
        setText(item);
        window.localStorage.text = item;
    }

    const Grid = () => {
        confirm({
            title: locale.dashboardQuestion.l,
            message: locale.dashboardQuestion.u,
            okText: locale.dashboardQuestion.n,
            cancelText: locale.dashboardQuestion.o,
            callback: (res) => {
                if (res) {
                    setGrid({});
                }
            }
        });
    }

    const Model = data => {
        setModel(data);

        const message = {
            type: 'wss',
            path: 'conversation-model',
            action: 'update',
            data: {
                model: data
            }
        };
        wsCall(message);
    }

    const DeleteTab = data => {
        confirm({
            title: locale.dashboardQuestion.ae,
            message: locale.dashboardQuestion.af,
            okText: locale.dashboardQuestion.x,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboardQuestion.ag, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'tab',
                        action: 'delete',
                        data: {
                            tabs: tabs
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Functions = data => {
        setFunctions(data);

        const message = {
            type: 'wss',
            path: 'conversation-function',
            action: 'update',
            data: {
                functions: data
            }
        };
        wsCall(message);
    }

    const Restart = () => {
        confirm({
            title: locale.dashboardQuestion.ab,
            message: locale.dashboardQuestion.ac,
            okText: locale.dashboardQuestion.aa,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboardQuestion.z, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'conversation-reset',
                        action: 'update',
                        data: {}
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Menu = () => {
        if (width > 992 && menu) {
            setMenu(false);
        } else if (width > 992 && !menu) {
            setMenu(true);
        } else if (width < 992) {
            setMenu(false);
            Dialog({view: 'dashboardQuestionMenu'})
        }
    }

    const ConversationSystem = () => {
        setToast({message: locale.dashboardHeaderConversation.j, color: 'info', display: 'bottom', duration: 3000});

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'get',
            data: {}
        };
        if (system) {
            setSystem(false);
            message.data.system = false;
        } else {
            setSystem(true);
            message.data.system = true;
        }
        wsCall(message);
    }

    const Files = () => {
        Dialog({view: 'dashboardQuestionFiles'});
    }

    const Tools = data => {
        return configuration.mobi.model.find(item => item.value === data)?.tools || null;
    }

    const Vision = data => {
        return configuration.mobi.model.find(item => item.value === data)?.vision || null;
    }

    const UpdateConversationReset = useCallback(data => {
        if (data.toast) {
            setMessages([]);
            setText('<p></p>');
            window.localStorage.text = '<p></p>';
        }
    }, []);

    const UpdateConversation = useCallback(data => {
        if (data.messages) {
            setMessages(data.messages);
            setSelectedFiles([]);
            setText('<p></p>');
        }
        if (data.error) {
            setToast({message: data.text, color: 'danger', display: 'bottom', duration: 3000});
        }
        Navigation({card: 'close'});
        window.scrollTo(0, 0);
    }, [Navigation]);

    const UpdateConversationModel = useCallback(data => {
        if (data.toast && !(model.includes('vision') || model.includes('Vision'))) {
            setToast({message: locale.dashboardQuestion.p, color: 'info', display: 'bottom', duration: 1000});
        } else if (data.toast && (model.includes('vision') || model.includes('Vision'))) {
            setToast({message: locale.dashboardQuestion.q, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardQuestion.p, model, locale.dashboardQuestion.q]);

    const UpdateConversationFunction = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardQuestion.p, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardQuestion.p]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-reset') {
            UpdateConversationReset(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationReset, setWsResponse]);

    useEffect(() => {
        if (wsResponse && wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-function') {
            UpdateConversationFunction(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationFunction, setWsResponse]);

    useEffect(() => {
        if (wsResponse && wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-model') {
            UpdateConversationModel(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationModel, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation') {
            UpdateConversation(wsResponse.data);
            setWsResponse({});
            setToast({});
            setSnackbar({});
        }
    }, [wsResponse, UpdateConversation, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-dashboard-question-header">
                <div className="mbsc-col" />
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-conversation-header-end">
                        <Button disabled={messages === 0 || !Empty(toast) || system} color="primary" variant="flat" className="mbsc-bold" onClick={() => Restart()} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faArrowRotateRight} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.w}</div>}
                        </Button>
                        <Button disabled={!Empty(toast) || tabs.length < 2 || system} color="primary" variant="flat" className="mbsc-bold" onClick={() => DeleteTab()} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faTrashCan} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.x}</div>}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-dashboard-question-main">
                <div className="mbsc-col cs-dashboard-question-body">
                    <CKEditor id="editor-focus" config={{ui:{poweredBy:{position:'inside', side:'right'}}}} editor={Editor} data={text} onChange={(event, editor) => {Text(editor.getData())}} disableWatchdog={true} />
                </div>
            </div>
            <div className="mbsc-row cs-dashboard-question-footer">
                {!loading &&
                    <div className="mbsc-col">
                        <div className="mbsc-row" >
                            {(configuration.ui.dashboard.model || configuration.ui.dashboard.functions) &&
                                <Button className="cs-dashboard-question-button-start" color="primary" variant="flat" id="dashboardQuestionMenu" disabled={Boolean(!Empty(toast) || system)} onClick={() => Menu()} >
                                    <FontAwesomeIcon color={(!Empty(toast) || system) ? 'secondary' : 'primary'} className="cs-dashboard-question-button-icon" icon={faEllipsisStrokeVertical} />&nbsp;
                                </Button>
                            }
                            <div className="mbsc-col cs-dashboard-question-select" >
                                {configuration.ui.dashboard.model && menu &&
                                    <Select
                                        animation="slide-down"
                                        inputStyle="underline"
                                        dropdown={false}
                                        label={locale.dashboardQuestion.e}
                                        labelStyle="floating"
                                        disabled={!Empty(toast) || system}
                                        rows={configuration.mobi.model.length}
                                        display="anchored"
                                        touchUi={false}
                                        data={configuration.mobi.model}
                                        value={model}
                                        onChange={(event) => Model(event.value)}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className="mbsc-col-auto cs-dashboard-question-actions">
                        <div className="mbsc-row">
                            <Button color={(!Empty(toast) || system) ? 'secondary' : 'primary'} variant="flat" id="dashboardQuestionMenuVision" disabled={Boolean(!Empty(toast) || system)} className="mbsc-bold" onClick={(ev) => Files()} >
                                <FontAwesomeIcon className="cs-dashboard-question-button-icon" icon={faFolderOpen} /><div className="cs-dashboard-question-menu-text">{selectedFiles.length === 0 ? locale.dashboardQuestion.b : locale.dashboardQuestion.b + ' (' + selectedFiles.length + ')'}</div>
                            </Button>
                            {!Empty(grid) &&
                                <Button color={(!Empty(toast) || system) ? 'secondary' : 'primary'} variant="flat" id="dashboardQuestionMenuVision" disabled={Boolean(!Empty(toast) || system)} className="mbsc-bold" onClick={(ev) => Grid()} >
                                    <FontAwesomeIcon className="cs-dashboard-question-button-icon" icon={faAlignSlash} /><div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.g}</div>
                                </Button>
                            }
                            <Button color={(!Empty(toast) || system || !text || text === '<p></p>') ? 'secondary' : 'primary'} variant="flat" disabled={!Empty(toast) || system || !text || text === '<p></p>'} className="mbsc-bold" onClick={(ev) => Message()} >
                                <FontAwesomeIcon className="cs-dashboard-question-button-icon" icon={faPaperPlane} /><div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.a}</div>
                            </Button>
                        </div>
                    </div>
                }
            </div>
            {configuration.ui.dashboard.functions && menu &&
                <div className="mbsc-row cs-dashboard-question-footer">
                    <div className="mbsc-col">
                        <div className="mbsc-row" >
                            <div className="mbsc-col cs-dashboard-question-select">
                                {Tools(model) &&
                                    <Select
                                        animation="slide-down"
                                        inputStyle="underline"
                                        dropdown={false}
                                        label={locale.dashboardQuestion.c}
                                        labelStyle="floating"
                                        disabled={!Empty(toast) || system}
                                        touchUi={false}
                                        rows={configuration.mobi.functions.length}
                                        display="anchored"
                                        selectMultiple={true}
                                        value={functions}
                                        data={configuration.mobi.functions}
                                        onChange={(event) => Functions(event.value)}
                                    />
                                }
                            </div>
                            <div className="mbsc-col-auto cs-dashboard-question-code">
                                {configuration.ui.dashboard.system && menu &&
                                    <Button className="cs-dashboard-question-button-end mbsc-bold" disabled={!Empty(toast)} color="primary" variant="flat" onClick={() => ConversationSystem()} >
                                        <FontAwesomeIcon color={!Empty(toast) ? 'secondary' : 'primary'} className="cs-dashboard-question-button-icon" icon={faCode} />&nbsp;
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {dialog.view === 'dashboardQuestionFiles' &&
                <DashboardQuestionFiles
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setToast={setToast}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    width={width}
                    Vision={Vision}
                />
            }
            {dialog.view === 'dashboardQuestionMenu' &&
                <DashboardQuestionMenu
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    configuration={configuration}
                    Model={Model}
                    model={model}
                    functions={functions}
                    Functions={Functions}
                />
            }
        </>
    );
}

export default DashboardQuestion;
