import React, {useCallback, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridColumns.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrid2Plus, faTrashCanList} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridColumns({Dialog, dialog, locale, columns, id}) {
    const menu = useRef([{add: true}, {delete: true}]);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const MenuItem = data => {
        let item = '';

        if (data.item.add) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-columns-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-columns-button mbsc-bold" onClick={() => Dialog({view: ('dashboardGridColumnNew' + id.toString())})} >
                    <FontAwesomeIcon className="cs-dashboard-grid-columns-icon" icon={faGrid2Plus}/> {locale.dashboardGridColumns.a}
                </Button>
            </li>;
        } else if (data.item.delete) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-columns-li">
                <Button color="primary" variant="flat" disabled={columns.length <= 2} className="cs-dashboard-grid-columns-button mbsc-bold" onClick={() => Dialog({view: ('dashboardGridColumnDelete' + id.toString())})} >
                    <FontAwesomeIcon className={columns.length <= 2 ? 'cs-dashboard-grid-columns-icon-disabled' : 'cs-dashboard-grid-columns-icon'} icon={faTrashCanList} /> {locale.dashboardGridColumns.b}
                </Button>
            </li>;
        }

        return item;
    }

    return (
        <Popup className="cs-dashboard-grid-columns-popup" display="anchored" anchor={document.getElementById(('dashboardGridColumns' + id.toString()))} buttons={[]} isOpen={dialog.view === ('dashboardGridColumns' + id.toString())} onClose={CloseDialog} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={MenuItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardGridColumns;
