import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Popup, Input, toast, RadioGroup, Radio, setOptions, Select, Checkbox} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginRegister.css';
import Loading from "../utils/Loading";
import Mobile from "../utils/Mobile";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginRegister({locale, dialog, Dialog, account, setToast}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [type, setType] = useState('mobile');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [country, setCountry] = useState(() => {
        if (window.localStorage.locale === 'en') {
            return 'us';
        } else if (window.localStorage.locale === 'es') {
            return 'mx';
        } else {
            return 'us';
        }
    });
    const [notifications, setNotifications] = useState(true);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [ccName, setCcName] = useState('');
    const [ccNumber, setCcNumber] = useState('');
    const [ccExpiration, setCcExpiration] = useState('');
    const [ccCvc, setCcCvc] = useState('');

    const CloseDialog = () => {
        Dialog({view: 'close'});
    }

    const Next = () => {
        if (step === 1 && !(password && confirm && firstName && lastName && password === confirm && ((type === 'mobile' && mobile) || (type === 'email' && email)))) {
            toast({message: locale.loginRegister.ag, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setStep(step + 1);
        }
    }

    const CC = data => {
        const string = data.replaceAll(' ', '');

        if (string.length <= 4) {
            setCcNumber(string);
        } else if (string.length > 4 && string.length <= 8) {
            const item = string.slice(0, 4) + ' ' + string.slice(4);
            setCcNumber(item);
        } else if (string.length > 8 && string.length <= 12) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) ;
            setCcNumber(item);
        } else if (string.length > 12 && string.length <= 16) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) + ' ' + string.slice(12, 16) ;
            setCcNumber(item);
        }
    }

    const Expiration = data => {
        const string = data.replaceAll('/', '');

        if (string.length < 2) {
            setCcExpiration(string);
        } else if (string.length === 2) {
            setCcExpiration(string + '/');
        } else if (string.length > 2 && string.length <= 4) {
            const item = string.slice(0, 2) + '/' + string.slice(2);
            setCcExpiration(item);
        }
    }

    const CVC = data => {
        if (data.length <= 4) {
            setCcCvc(data);
        }
    }

    const Back = () => {
        setStep(step - 1);
    }

    const Header = () => {
        if (step === 0) {
            return locale.loginRegister.a;
        } else if (step === 1) {
            return locale.loginRegister.w;
        }
    }

    const Type = data => {
        setType(data.target.value);
    }

    const Buttons = () => {
        if (step === 0) {
            return [{text: locale.loginRegister.b, cssClass: 'cs-login-register-save', handler: ()=> Next()}]
        } else if (step === 1) {
            return [{text: locale.loginRegister.v, cssClass: 'cs-login-register-save', handler: ()=> Back()}, {text: locale.loginRegister.u, cssClass: 'cs-login-register-save', handler: ()=> Next()}]
        }
    }

    const Check = () => {
        const message = {
            type: 'rest',
            path: 'account-check',
            action: 'get',
            data: {
                account: account
            }
        };
        restCall(message);
    }

    const GetAccountCheck = useCallback(data => {
        if (data.toast) {
            setLoading(false);
        }
        if (data.error) {
            toast({message: locale.loginRegister.c, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.loginRegister.c]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'account-check') {
            GetAccountCheck(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetAccountCheck, setRestResponse]);

    useEffect(() => {
        Check();
    }, []);

    return (
        <Popup className="cs-login-register-popup" width={600} maxHeight={700} display="center" headerText={Header()} buttons={Buttons()} isOpen={dialog.view === 'loginRegister'} onClose={CloseDialog}>
            {loading &&
                <Loading />
            }
            {step === 0 &&
                <>
                    <div className="cs-login-register-helper">
                        <p>{locale.loginRegister.g}</p>
                    </div>
                    <div className="cs-login-register-helper">
                        <p><span className="mbsc-bold">{locale.loginRegister.h}:</span> {locale.loginRegister.i}</p>
                    </div>
                    <div className="cs-login-register-helper">
                        <p>{locale.loginRegister.j}:</p>
                    </div>
                    <div className="cs-login-register-helper">
                        <p className="mbsc-bold">{locale.loginRegister.k}</p>
                    </div>
                    <div className="cs-login-register-helper">
                        <p>{locale.loginRegister.l}:</p>
                        <ul>
                            <li>
                                {locale.loginRegister.m}
                            </li>
                            <li>
                                {locale.loginRegister.n}
                            </li>
                            <li>
                                {locale.loginRegister.o}
                            </li>
                        </ul>
                    </div>
                    <div className="cs-login-register-helper">
                        <p className="mbsc-bold">{locale.loginRegister.p}</p>
                    </div>
                    <div className="cs-login-register-helper">
                        <p>{locale.loginRegister.q}:</p>
                        <ul>
                            <li>
                                {locale.loginRegister.r}
                            </li>
                            <li>
                                {locale.loginRegister.s}
                            </li>
                        </ul>
                    </div>
                    <div className="cs-login-register-helper">
                        <p>{locale.loginRegister.t}:</p>
                    </div>
                    <RadioGroup name="type">
                        <Radio className={type === 'mobile' ? 'mbsc-bold'  : ''} label={locale.loginRegister.e} value="mobile" checked={type === 'male'} onChange={Type} defaultChecked={true} />
                        <Radio className={type === 'email' ? 'mbsc-bold ' : ''} label={locale.loginRegister.f} value="email" checked={type === 'female'} onChange={Type} />
                    </RadioGroup>
                </>
            }
            {step === 1 &&
                <>
                    <div className="cs-login-register-helper">
                        <p>{locale.loginRegister.x}</p>
                    </div>
                    <Input inputStyle="underline" label={locale.loginRegister.y} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={(ev) => setFirstName(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.loginRegister.z} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={(ev) => setLastName(ev.target.value)} />
                    {type === 'email' &&
                        <Input inputStyle="underline" label={locale.loginRegister.f} labelStyle="floating" type="text" name="email" value={email} onChange={(ev) => setEmail(ev.target.value)} />
                    }
                    {type === 'mobile' &&
                        <div className="mbsc-grid cs-login-register-grid">
                            <div className="mbsc-row">
                                <div className="mbsc-col-4 cs-login-register-inputs-left">
                                    <Select
                                        animation="slide-down"
                                        dropdown={false}
                                        inputStyle="underline"
                                        label={locale.loginRegister.aa}
                                        labelStyle="floating"
                                        rows={locale.loginRegister.ab.length}
                                        display="anchored"
                                        touchUi={false}
                                        value={country}
                                        data={locale.loginRegister.ab}
                                        disabled={mobile.length !== 0}
                                        onChange={(event) => setCountry(event.value)}
                                    />
                                </div>
                                <div className="mbsc-col-8 cs-login-register-inputs-right">
                                    <Input className="cs-login-register-inputs-right-input" label={locale.loginRegister.k} inputStyle="underline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile, country: country})} />
                                </div>
                            </div>
                        </div>
                    }
                    {type === 'mobile' &&
                        <Checkbox
                            color="info"
                            label={locale.loginRegister.ac}
                            checked={notifications}
                            onChange={(ev) => setNotifications(ev.target.checked)}
                        />
                    }
                    <Input inputStyle="underline" label={locale.loginRegister.ad} labelStyle="floating" type="password" name="password" passwordToggle={true} value={password} onChange={(ev) => {setPassword(ev.target.value)}} />
                    <Input inputStyle="underline" label={locale.loginRegister.ae} labelStyle="floating" type="password" name="confirm" passwordToggle={true} value={confirm} onChange={(ev) => {setConfirm(ev.target.value)}} />
                    <div className="cs-login-register-helper">
                        <p>{locale.loginRegister.af}</p>
                    </div>
                </>
            }
            {step === 2 &&
                <>
                    <Input inputStyle="underline" label={locale.loginRegister.al} placeholder={locale.loginRegister.bi} labelStyle="floating" type="text" name="ccName" value={ccName} onChange={ev => setCcName(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.loginRegister.am} placeholder={locale.loginRegister.bj} labelStyle="floating" type="text" name="ccNumber" value={ccNumber} onChange={ev => CC(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.loginRegister.an} placeholder={locale.loginRegister.bl} labelStyle="floating" type="text" name="ccExpiration" value={ccExpiration} onChange={ev => Expiration(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.loginRegister.ao} placeholder={locale.loginRegister.bk} labelStyle="floating" type="text" name="ccCvc" value={ccCvc} onChange={ev => CVC(ev.target.value)} />
                </>
            }
        </Popup>
    );
}

export default LoginRegister;
