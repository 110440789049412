import React, {useCallback, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderConversation.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxOpen, faBoxTaped} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderConversation({Dialog, dialog, locale, messages, wait}) {
    const menu = useRef([{conversations: true}, {save: true}]);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const MenuItem = data => {
        let item = '';

        if (data.item.conversations) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-conversation-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-conversation-button mbsc-bold" disabled={!Empty(wait)} onClick={() => Dialog({view: 'dashboardHeaderConversations'})} >
                    <FontAwesomeIcon className={!Empty(wait) ? 'cs-dashboard-header-conversation-icon-disabled' : 'cs-dashboard-header-conversation-icon'} icon={faBoxTaped} /> {locale.dashboardHeaderConversation.a}
                </Button>
            </li>;
        } else if (data.item.save) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-conversation-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-conversation-button mbsc-bold" disabled={messages === 0 || !Empty(wait)} onClick={() => {Dialog({view: 'dashboardHeaderConversationSave'})}} >
                    <FontAwesomeIcon className={(messages === 0 || !Empty(wait)) ? 'cs-dashboard-header-conversation-icon-disabled' : 'cs-dashboard-header-conversation-icon'} icon={faBoxOpen} /> {locale.dashboardHeaderConversation.b}
                </Button>
            </li>;
        }

        return item;
    }

    return (
        <Popup className="cs-dashboard-header-conversation-popup" display="anchored" anchor={document.getElementById('dashboardHeaderConversation')} buttons={[]} isOpen={dialog.view === 'dashboardHeaderConversation'} onClose={CloseDialog} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={MenuItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardHeaderConversation;
