import React, {useEffect, useState, useCallback, useContext} from 'react';
import {CalendarNav, CalendarNext, CalendarPrev, CalendarToday, Eventcalendar, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardCalendar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCirclePlus, faSparkles, faXmark, faPipe} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import DashboardCalendarEvent from '../dialogs/DashboardCalendarEvent';
import DashboardCalendarNew from "../dialogs/DashboardCalendarNew";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendar({locale, Navigation, navigation, width, calendar, setCalendar, Dialog, configuration, dialog, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [settings, setSettings] = useState({});
    const [event, setEvent] = useState({});
    const [create, setCreate] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [info, setInfo] = useState(false);

    const Header = () => {
        return <>
            {width > 576 && navigation.tab !== 'day' &&
                <CalendarNav className="cs-dashboard-calendar-controls-button" />
            }
            <div className="cs-dashboard-calendar-controls cs-dashboard-calendar-controls-button">
                <CalendarPrev />
                <CalendarToday />
                <CalendarNext />
            </div>
        </>;
    }

    const Calendar = data => {
        setToast({message: locale.dashboardCalendar.a, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'wss',
            path: 'calendar',
            action: 'get',
            data: {
                view: navigation.tab,
                start: dayjs(data.firstDay).format(),
                end: dayjs(data.lastDay).format()
            }
        };
        wsCall(message);
    }

    const Event = data => {
        return <>
            <div>{data.title}</div>
            <div>
                <div className="cs-dashboard-calendar-event-location">{Location(data.id)}</div>
            </div>
        </>;
    }

    const New = data => {
        setCreate({start: data.start, end: data.end});
        Dialog({view: 'dashboardCalendarNew'});
    }

    const Location = data => {
        const item = calendar.find(item => item.id === data);
        return item ? item.location : '';
    }

    const Open = data => {
        setEvent(data.event);
        Dialog({view: 'dashboardCalendarEvent', tab: 'event'})
    }

    const Info = () => {
        if (info) {
            setInfo(false);
        } else if (!info) {
            setInfo(true);
        }
    }

    const GetCalendar = useCallback(data => {
        if (data.calendar) {
            setCalendar(data.calendar);
        }
    }, []);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'calendar') {
            GetCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetCalendar, setWsResponse]);

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (navigation.tab === 'day') {
            setCalendar([]);
            setSettings(configuration.mobi.calendar.day);
        } else if (navigation.tab === 'week') {
            setCalendar([]);
            setSettings(configuration.mobi.calendar.week);
        } else if (navigation.tab === 'month') {
            setCalendar([]);
            setSettings(configuration.mobi.calendar.month);
        }
    }, []);

    return (
        <>
            <div className="mbsc-row cs-dashboard-calendar-header">
                <div className="mbsc-col cs-dashboard-calendar-col">
                    <Button onClick={() => Info()} color="secondary" variant="flat" className="mbsc-bold cs-dashboard-calendar-button-start" >
                        <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faSparkles} />{width > 768 && '\u00A0'}
                        {info &&
                            <div className="cs-dashboard-calendar-menu-text">
                                {locale.dashboardCalendar.d}
                            </div>
                        }
                    </Button>
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-calendar-header-end">
                        <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => Dialog({view: 'dashboardCalendarNew'})} >
                            <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faCalendarCirclePlus} />{width > 768 && <div className="cs-dashboard-calendar-menu-text">{locale.dashboardCalendar.e}</div>}
                        </Button>
                        <Button color="secondary" variant="flat" >
                            <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faPipe} />
                        </Button>
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-button-end" onClick={() => Navigation({card: 'close'})} >
                            <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {!Empty(settings) && !refresh &&
                <div className="mbsc-row cs-dashboard-calendar-main">
                    <div className="mbsc-col cs-dashboard-calendar-col">
                        <Eventcalendar
                            clickToCreate="double"
                            onEventCreate={function(event, inst){New(event.event); return false}}
                            dragToCreate={true}
                            view={settings}
                            data={calendar}
                            renderEventContent={Event}
                            renderHeader={Header}
                            onPageLoading={function(event, inst){Calendar(event)}}
                            onEventClick={function(event, inst){Open(event)}}
                        />
                    </div>
                </div>
            }
            {!Empty(event) && dialog.view === 'dashboardCalendarEvent' &&
                <DashboardCalendarEvent
                    Dialog={Dialog}
                    dialog={dialog}
                    event={event}
                    locale={locale}
                    navigation={navigation}
                    Navigation={Navigation}
                    setRefresh={setRefresh}
                    width={width}
                    setToast={setToast}
                />
            }
            {dialog.view === 'dashboardCalendarNew' &&
                <DashboardCalendarNew
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    create={create}
                    setCreate={setCreate}
                    setRefresh={setRefresh}
                    width={width}
                    setToast={setToast}
                />
            }
        </>
    );
}

export default DashboardCalendar;
