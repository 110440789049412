import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, Input, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderConversationSave.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderConversationSave({locale, dialog, Dialog, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [conversation, setConversation] = useState('');

    const CloseDialog = useCallback(() => {
        setConversation('');
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        const message = {
            type: 'wss',
            path: 'conversation-archive',
            action: 'put',
            data: {
                conversation: conversation
            }
        };
        wsCall(message);
    }

    const PutConversationArchive = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeaderConversationSave.a, color: 'info', display: 'bottom', duration: 1000});
            CloseDialog();
        }
    }, [CloseDialog, locale.dashboardHeaderConversationSave.a]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'conversation-archive') {
            PutConversationArchive(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutConversationArchive, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-conversation-save-popup" display="center" width="600" headerText={locale.dashboardHeaderConversationSave.b} buttons={[{text:locale.dashboardHeaderConversationSave.c, cssClass: 'cs-dashboard-header-conversation-save-button', disabled: !conversation, handler: () => Save()}]} isOpen={dialog.view === 'dashboardHeaderConversationSave'} onClose={CloseDialog}>
            <Input inputStyle="underline" label={locale.dashboardHeaderConversationSave.d} labelStyle="floating" type="text" name="conversation" value={conversation} onChange={(ev) => {setConversation(ev.target.value)}} />
        </Popup>
    );
}

export default DashboardHeaderConversationSave;
