import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardTodos.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrid2Plus, faPipe, faChevronLeft, faSparkles, faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import DashboardTodosTodo from "../dialogs/DashboardTodosTodo";
import DashboardTodosNew from "../dialogs/DashboardTodosNew";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardTodos({locale, Navigation, Dialog, navigation, dialog, width, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [todo, setTodo] = useState({});
    const [todos, setTodos] = useState([]);
    const [children, setChildren] = useState([]);
    const [group, setGroup] = useState(null);
    const [groupText, setGroupText] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [reload, setReload] = useState(false);
    const [info, setInfo] = useState(false);

    const MenuItem = data => {
        return <li>
            {children.length !== 0 && data.item.id === 0 && <FontAwesomeIcon icon={faChevronLeft} />} <span className="mbsc-bold mbsc-txt-muted">{data.item.name}</span>
        </li>;
    }

    const Todo = data => {
        setReload(true);

        if (children.length === 0) {
            setGroup(data);
            setChildren(todos[data].children);
        } else if (children.length !== 0 && children[data].id === 0) {
            setGroup(null);
            setChildren([]);
        } else {
            setToast({message: locale.dashboardTodos.f, color: 'info', display: 'bottom', duration: 1000});

            const message = {
                type: 'wss',
                path: 'todo',
                action: 'get',
                data: {
                    todo: children[data].id
                }
            };
            wsCall(message);
        }
    }

    const New = () => {
        if (group !== null) {
            setGroupText(todos[group].name);
        } else {
            setGroupText('General');
        }
        Dialog({view: 'dashboardTodosNew'});
    }

    const Info = () => {
        if (info) {
            setInfo(false);
        } else if (!info) {
            setInfo(true);
        }
    }

    const Todos = useCallback(() => {
        setToast({message: locale.dashboardTodos.g, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'wss',
            path: 'todos',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }, [locale.dashboardTodos.g, wsCall]);

    const GetTodo = useCallback(data => {
        if (data.todo) {
            setTodo(data.todo);
            Dialog({view: 'dashboardTodosTodo'});
        }
    }, [Dialog]);

    const GetTodos = useCallback(data => {
        if (data.todos) {
            setTodos(data.todos);
        }
        if (data.todos.length === 0) {
            setToast({message: locale.dashboardTodos.e, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (refresh) {
            setTodo({});
            setGroup(null);
            setChildren([]);
            setRefresh(false);
        }
    }, [locale.dashboardTodos.e, refresh]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'todos') {
            GetTodos(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetTodos, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'todo') {
            GetTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetTodo, setWsResponse]);

    useEffect(() => {
        if (refresh) {
            Todos();
        }
    }, [refresh, Todos]);

    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        Todos();
    }, []);

    return (
        <>
            <div className="mbsc-row cs-dashboard-todos-header">
                <div className="mbsc-col cs-dashboard-todos-col">
                    <Button onClick={() => Info()} color="secondary" variant="flat" className="mbsc-bold cs-dashboard-todos-button-start" >
                        <FontAwesomeIcon className="cs-dashboard-todos-icon" icon={faSparkles} />{width > 768 && '\u00A0'}
                        {info &&
                            <div className="cs-dashboard-todos-menu-text">
                                {locale.dashboardTodos.c}
                            </div>
                        }
                    </Button>
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-todos-header-end">
                        <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => New()} >
                            <FontAwesomeIcon className="cs-dashboard-todos-icon" icon={faGrid2Plus} />{width > 768 && <div className="cs-dashboard-todos-menu-text">{locale.dashboardTodos.h}</div>}
                        </Button>
                        <Button color="secondary" variant="flat" >
                            <FontAwesomeIcon className="cs-dashboard-todos-icon" icon={faPipe} />
                        </Button>
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-todos-button-end" onClick={() => Navigation({card: 'close'})} >
                            <FontAwesomeIcon className="cs-dashboard-todos-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {!reload && !refresh &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={MenuItem}
                    data={children.length !== 0 ? children : todos}
                    onItemTap={event => Todo(event.index)}
                />
            }
            {!Empty(todo) && dialog.view === 'dashboardTodosTodo' &&
                <DashboardTodosTodo
                    Dialog={Dialog}
                    dialog={dialog}
                    todo={todo}
                    locale={locale}
                    navigation={navigation}
                    Navigation={Navigation}
                    setRefresh={setRefresh}
                    width={width}
                    setToast={setToast}
                />
            }
            {dialog.view === 'dashboardTodosNew' &&
                <DashboardTodosNew
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    navigation={navigation}
                    Navigation={Navigation}
                    setRefresh={setRefresh}
                    width={width}
                    groupText={groupText}
                    setToast={setToast}
                />
            }
        </>
    );
}

export default DashboardTodos;
