import React, {useEffect, useState} from 'react';
import {Button, alert, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBrainCircuit, faFileCsv, faMessageArrowUp, faMessageLines, faPipe, faTableColumns, faTableRows, faWaveformLines, faMessageXmark} from "@fortawesome/pro-duotone-svg-icons";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {Doughnut, Bar, Line, PolarArea, Radar, Pie, Bubble, Scatter} from "react-chartjs-2";
import 'react-data-grid/lib/styles.css';
import DataGrid, {textEditor, SelectColumn} from 'react-data-grid';
import '../styles/dashboardConversation.css';
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import DashboardGridRows from "../dialogs/DashboardGridRows";
import DashboardGridColumns from "../dialogs/DashboardGridColumns";
import DashboardGridColumnNew from "../dialogs/DashboardGridColumnNew";
import DashboardGridColumnDelete from "../dialogs/DashboardGridColumnDelete";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardConversation({width, item, configuration, locale, Dialog, setMessageAnchor, setMessageData, user, setToast, Read, grid, setGrid, dialog, system, Delete}) {
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([{key: 'id', name: '', frozen: true, resizable: false, width: 50}, SelectColumn]);
    const [refresh, setRefresh] = useState(true);
    const [info, setInfo] = useState(false);

    const Model = data => {
        const item = configuration.mobi.model.find(item => item.value === data);
        return item ? item.short : '';
    }

    const Info = data => {
        if ((width > 768 && info) || (width < 768 && info && !data)) {
            setInfo(false);
        } else if ((width > 768 && !info) || (width < 768 && !info && !data)) {
            setInfo(true);
        } else if (width < 768 && data) {
            setInfo(false);
            setMessageAnchor(document.getElementById(data));
            const itemInfo = {
                model: item.model ? item.model : null,
                functions: item.functions ? item.functions : null,
                usage: item.usage ? item.usage : null,
                context: item.context ? item.context : null
            }
            setMessageData(itemInfo);
            Dialog({view: 'dashboardInfo'});
        }
    }

    const Key = data => {
        return data.id;
    }

    const Share = () => {
        const itemGrid = {...grid};
        itemGrid.rows = rows;
        itemGrid.columns = columns;
        setGrid(itemGrid);

        alert({
            title: locale.dashboardConversation.p,
            message: locale.dashboardConversation.q
        });
    }

    const Select = data => {
        setSelectedRows(data);
    }

    const Table = data => {
        setRows(data);
    }

    const Export = () => {
        let itemColumns = columns;
        let itemRows = rows;

        itemColumns.splice(0, 2);

        itemColumns = itemColumns.map(column => {
            const {key, name} = column;
            return {key, name};
        });

        itemRows = itemRows.map(row => {
            const {id, ...rest} = row;
            return rest;
        });

        const csvContent = CSV(itemColumns, itemRows);

        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        downloadLink.download = 'data.csv';
        downloadLink.style.display = 'none';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const Mobile = data => {
        let formattedText = '';

        if (data.startsWith('+1')) {
            const number = data.slice(2);

            formattedText = `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
        } else if (data.startsWith('+52')) {
            const number = data.slice(3);

            formattedText = `(${number.slice(0, 2)}) ${number.slice(2, 6)}-${number.slice(6, 10)}`;
        } else {
            formattedText = data;
        }

        return formattedText;
    }

    const Content = data => {
        if (data.content && typeof data.content === 'string') {
            return data.content;
        } else if (data.content && typeof data.content === 'object' && Array.isArray(data.content)) {
            const textContent = data.content.find(contentItem => contentItem?.type && (contentItem.type === 'text' || contentItem.type === 'tool_result'));

            if (textContent) {
                return textContent.text ? textContent.text : textContent.content;
            }
        } else if (!data.content && data.tool_calls && typeof data.tool_calls === 'object') {
            let jsonString = JSON.stringify(data.tool_calls, null, 2);

            jsonString = jsonString.replace(/\n/g, '<br>');
            jsonString = jsonString.replace(/^( +)/gm, match => '&nbsp;'.repeat(match.length));
            jsonString = jsonString.replace(/"([^"]+)":/g, '$1:');

            return jsonString;
        }
    }

    const User = () => {
        if (user.type === 'mobile') {
            return Mobile(user.id);
        } else {
            return user.id;
        }
    }

    const ToolUse = data => {
        if (data.tool_calls) {
            return true;
        } else if (data.content && Array.isArray(data.content) && data.content.length !== 0 && data.content.some(contentItem => typeof contentItem === 'object' && contentItem !== null && (contentItem.type === 'tool_use' || contentItem.type === 'tool_result'))) {
            return true;
        } else {
            return false;
        }
    }

    const ToolDetail = data => {
        if (data.tool_calls && Array.isArray(data.tool_calls) && data.tool_calls.length !== 0) {
            const textContent = data.tool_calls.find(toolItem => toolItem?.function && toolItem.function.name);

            if (textContent) {
                return textContent.function.name;
            }
        } else if (data.content && Array.isArray(data.content) && data.content.length !== 0) {
            const textContent = data.content.find(toolItem => toolItem?.type && toolItem.type === 'tool_use');

            if (textContent) {
                return textContent.name;
            }
        }
    }

    const CSV = (itemColumns, itemRows) => {
        const headerRow = itemColumns.map(column => column.name).join(',');
        const dataRows = itemRows.map(row => Object.values(row).join(',')).join('\n');
        return headerRow + '\n' + dataRows;
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (item.grid && !Empty(item.grid) && item.grid.rows && item.grid.rows.length !== 0) {
            let itemRows = item.grid.rows;
            itemRows = itemRows.map((item, index) => ({
                ...item,
                id: index + 1
            }));
            setRows(itemRows);
        }
        if (item.grid && !Empty(item.grid) && item.grid.columns && item.grid.columns.length !== 0) {
            let itemColumns = item.grid.columns;
            itemColumns = itemColumns.map((item) => ({
                ...item,
                renderEditCell: textEditor
            }));
            setColumns(columns.concat(itemColumns));
        }
    }, []);

    return (
        <>
            <div className="mbsc-row cs-dashboard-conversation-header">
                <div className="mbsc-col cs-dashboard-conversation-col">
                    {item.role === 'assistant' && !ToolUse(item) &&
                        <Button id={item.id} onClick={() => Info(item.id)} color="secondary" variant="flat" className="mbsc-bold cs-dashboard-conversation-button-start" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info && !configuration.ui.header.personality &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {configuration.ui.dashboard.model ? Model(item.model) : locale.dashboardConversation.r}
                                    {item.context && item.usage && ' - ' + item.usage.total_tokens + ' of ' + item.context + ' tokens'}
                                </div>
                            }
                        </Button>
                    }
                    {item.role === 'assistant' && ToolUse(item) &&
                        <Button onClick={() => Info()} color="secondary" variant="flat" className="mbsc-bold cs-dashboard-conversation-button-start" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {locale.dashboardConversation.b}: {ToolDetail(item)}
                                </div>
                            }
                        </Button>
                    }
                    {item.role === 'system' &&
                        <Button onClick={() => Info()} color="secondary" variant="flat" className="mbsc-bold cs-dashboard-conversation-button-start" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {locale.dashboardConversation.a}
                                </div>
                            }
                        </Button>
                    }
                    {(item.role === 'tool' || (item.role === 'user' && ToolUse(item))) &&
                        <Button onClick={() => Info()} color="secondary" variant="flat" className="mbsc-bold cs-dashboard-conversation-button-start" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {locale.dashboardConversation.c}
                                </div>
                            }
                        </Button>
                    }
                    {item.role === 'user' && !ToolUse(item) &&
                        <Button onClick={() => Info()} color="secondary" variant="flat" className="mbsc-bold cs-dashboard-conversation-button-start" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faMessageLines} />{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {User()}
                                </div>
                            }
                        </Button>
                    }
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-conversation-header-end">
                        {!system && item.role === 'assistant' && item.grid && !Empty(item.grid) &&
                            <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => Export()} >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faFileCsv} />{width > 768 && <div className="cs-dashboard-conversation-menu-text">{locale.dashboardConversation.n}</div>}
                            </Button>
                        }
                        {!system && item.role === 'assistant' && item.grid && !Empty(item.grid) &&
                            <Button color="primary" variant="flat" className="mbsc-bold" disabled={!Empty(grid)} onClick={() => Share()} >
                                <FontAwesomeIcon className={columns.length <= 2 ? 'cs-dashboard-conversation-icon-disabled' : 'cs-dashboard-conversation-icon'} icon={faMessageArrowUp} />{width > 768 && <div className="cs-dashboard-conversation-menu-text">{locale.dashboardConversation.m}</div>}
                            </Button>
                        }
                        {!system && item.role === 'assistant' && item.grid && !Empty(item.grid) &&
                            <Button color="primary" variant="flat" className="mbsc-bold" id={'dashboardGridRows' + item.id.toString()} onClick={() => Dialog({view: ('dashboardGridRows' + item.id.toString())})} >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faTableRows} />{width > 768 && <div className="cs-dashboard-conversation-menu-text">{locale.dashboardConversation.l}</div>}
                            </Button>
                        }
                        {!system && item.role === 'assistant' && item.grid && !Empty(item.grid) &&
                            <Button color="primary" variant="flat" className="mbsc-bold" id={'dashboardGridColumns' + item.id.toString()} onClick={() => Dialog({view: ('dashboardGridColumns' + item.id.toString())})} >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faTableColumns} />{width > 768 && <div className="cs-dashboard-conversation-menu-text">{locale.dashboardConversation.o}</div>}
                            </Button>
                        }
                        {!system && item.role === 'assistant' && item.grid && !Empty(item.grid) &&
                            <Button color="secondary" variant="flat" >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faPipe} />
                            </Button>
                        }
                        {!system && item.role === 'assistant' && item.content && !item.function_call &&
                            <Button color="primary" variant="flat" className="mbsc-bold"  onClick={() => Read(Array.isArray(item.content) ? item.content[0].text : item.content)} >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faWaveformLines} />{width > 768 && <div className="cs-dashboard-conversation-menu-text">{locale.dashboardConversation.k}</div>}
                            </Button>
                        }
                        {system && item.role !== 'system' &&
                            <Button color="primary" variant="flat" className="mbsc-bold"  onClick={() => Delete(item.id)} >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faMessageXmark} />{width > 768 && '\u00A0'}
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-dashboard-conversation-main">
                <div className="mbsc-col cs-dashboard-conversation-editor">
                    <CKEditor id="editor-conversation" onReady={editor => {editor.enableReadOnlyMode('editor-conversation')}} editor={Editor} data={Content(item)} disableWatchdog={true} />
                </div>
            </div>
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'doughnut' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-lg-6 mbsc-col-xl-4">
                        <Doughnut options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'pie' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-lg-6 mbsc-col-xl-4">
                        <Pie options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'polar' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-lg-6 mbsc-col-xl-4">
                        <PolarArea options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'radar' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <Radar options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'bar' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12">
                        <Bar options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'line' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12">
                        <Line options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'bubble' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12">
                        <Bubble options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.chart && item.chart.options && item.chart.data && item.chart.type === 'scatter' &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-conversation-charts">
                    <div className="mbsc-col-12">
                        <Scatter options={item.chart.options} data={item.chart.data} />
                    </div>
                </div>
            }
            {item.role === 'assistant' && item.grid && !Empty(item.grid) &&
                <div className="mbsc-row cs-dashboard-conversation-main">
                    <div className="mbsc-col cs-dashboard-conversation-col">
                        {refresh ?
                            <Loading />
                            :
                            <DataGrid
                                className={"rdg-light"}
                                columns={columns}
                                rows={rows}
                                onRowsChange={Table}
                                rowKeyGetter={Key}
                                selectedRows={selectedRows}
                                onSelectedRowsChange={Select}
                            />
                        }
                    </div>
                </div>
            }
            {dialog.view === ('dashboardGridRows' + item.id.toString()) &&
                <DashboardGridRows
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setRefresh={setRefresh}
                    rows={rows}
                    setRows={setRows}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    id={item.id}
                />
            }
            {dialog.view === ('dashboardGridColumns' + item.id.toString()) &&
                <DashboardGridColumns
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    columns={columns}
                    id={item.id}
                />
            }
            {dialog.view === ('dashboardGridColumnNew' + item.id.toString()) &&
                <DashboardGridColumnNew
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    rows={rows}
                    setRows={setRows}
                    columns={columns}
                    setColumns={setColumns}
                    setRefresh={setRefresh}
                    id={item.id}
                    setToast={setToast}
                />
            }
            {dialog.view === ('dashboardGridColumnDelete' + item.id.toString()) &&
                <DashboardGridColumnDelete
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    columns={columns}
                    setColumns={setColumns}
                    setRefresh={setRefresh}
                    rows={rows}
                    setRows={setRows}
                    id={item.id}
                    setToast={setToast}
                />
            }
        </>
    );
}

export default DashboardConversation;
