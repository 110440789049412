import React, {useCallback} from 'react';
import {Popup, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderTabs.css';
import Truncate from "../utils/Truncate";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderTabs({dialog, Dialog, tabs, width, Tab}) {

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Item = data => {
        return <li key={data.item.id}>
            <div className="mbsc-bold mbsc-txt-muted">{width < 992 ? Truncate(data.item.name, 15) : Truncate(data.item.text, 30)}</div>
        </li>;
    }

    const Open = data => {
        Tab(tabs[data]);
        CloseDialog();
    }

    return (
        <Popup className="cs-dashboard-header-tabs-popup" display="anchored" anchor={document.getElementById('dashboardHeaderTabs')} buttons={[]} isOpen={dialog.view === 'dashboardHeaderTabs'} onClose={CloseDialog}>
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={Item}
                data={tabs}
                onItemTap={event => Open(event.index)}
            />
        </Popup>
    );
}

export default DashboardHeaderTabs;
