import React, {useCallback, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridRows.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDeleteRight, faGrid2Plus, faUpToLine, faDownFromLine} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridRows({Dialog, dialog, locale, setRefresh, rows, setRows, selectedRows, setSelectedRows, id}) {
    const menu = useRef([{new: true}, {above: true}, {below: true}, {delete: true}]);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const MenuItem = data => {
        let item = '';

        if (data.item.new) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-rows-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-rows-button mbsc-bold" onClick={() => {Plus(); CloseDialog();}} >
                    <FontAwesomeIcon className="cs-dashboard-grid-rows-icon" icon={faGrid2Plus}/> {locale.dashboardGridRows.a}
                </Button>
            </li>;
        } else if (data.item.above) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-rows-li">
                <Button color="primary" variant="flat" disabled={selectedRows.size === 0} className="cs-dashboard-grid-rows-button mbsc-bold" onClick={() => {Above(); CloseDialog();}} >
                    <FontAwesomeIcon className={selectedRows.size === 0 ? 'cs-dashboard-grid-rows-icon-disabled' : 'cs-dashboard-grid-rows-icon'} icon={faUpToLine} /> {locale.dashboardGridRows.c}
                </Button>
            </li>;
        } else if (data.item.below) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-rows-li">
                <Button color="primary" variant="flat" disabled={selectedRows.size === 0} className="cs-dashboard-grid-rows-button mbsc-bold" onClick={() => {Below(); CloseDialog();}} >
                    <FontAwesomeIcon className={selectedRows.size === 0 ? 'cs-dashboard-grid-rows-icon-disabled' : 'cs-dashboard-grid-rows-icon'} icon={faDownFromLine} /> {locale.dashboardGridRows.d}
                </Button>
            </li>;
        } else if (data.item.delete) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-rows-li">
                <Button color="primary" variant="flat" disabled={selectedRows.size === 0} className="cs-dashboard-grid-rows-button mbsc-bold" onClick={() => {Minus(); CloseDialog();}} >
                    <FontAwesomeIcon className={selectedRows.size === 0 ? 'cs-dashboard-grid-rows-icon-disabled' : 'cs-dashboard-grid-rows-icon'} icon={faDeleteRight} /> {locale.dashboardGridRows.b}
                </Button>
            </li>;
        }

        return item;
    }

    const Plus = () => {
        let itemNew = {};
        const itemRows = [...rows];
        for (let prop in itemRows[0]) {
            if (prop === 'id') {
                itemNew[prop] = itemRows.length + 1;
            } else {
                itemNew[prop] = '';
            }
        }
        itemRows.push(itemNew);
        setRows(itemRows);
        setRefresh(true);
    }

    const Above = () => {
        let itemNew = {};
        const itemRows = [...rows];
        const insertIndex = Math.min(...selectedRows) - 1;

        for (let prop in itemRows[0]) {
            if (prop === 'id') {
                itemNew[prop] = insertIndex + 1;
            } else {
                itemNew[prop] = '';
            }
        }

        itemRows.splice(insertIndex, 0, itemNew);

        for (let i = insertIndex + 1; i < itemRows.length; i++) {
            itemRows[i].id = i + 1;
        }

        const updatedIndexSet = new Set(
            Array.from(selectedRows).map(id => (id >= insertIndex + 1 ? id + 1 : id))
        );

        setRows(itemRows);
        setSelectedRows(updatedIndexSet);
        setRefresh(true);
    }

    const Below = () => {
        let itemNew = {};
        const itemRows = [...rows];
        const insertIndex = Math.max(...selectedRows);

        for (let prop in itemRows[0]) {
            if (prop === 'id') {
                itemNew[prop] = insertIndex + 1;
            } else {
                itemNew[prop] = '';
            }
        }

        itemRows.splice(insertIndex, 0, itemNew);

        for (let i = insertIndex + 1; i < itemRows.length; i++) {
            itemRows[i].id = i + 1;
        }
        setRows(itemRows);
        setRefresh(true);
    }

    const Minus = () => {
        const arrayRows = rows.filter(item => !selectedRows.has(item.id));

        arrayRows.forEach((item, index) => {
            item.id = index + 1;
        });

        setSelectedRows(new Set());
        setRows(arrayRows);
        setRefresh(true);
    }

    return (
        <Popup className="cs-dashboard-grid-rows-popup" display="anchored" anchor={document.getElementById(('dashboardGridRows' + id.toString()))} buttons={[]} isOpen={dialog.view === ('dashboardGridRows' + id.toString())} onClose={CloseDialog} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={MenuItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardGridRows;
