import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Popup, Input, Datepicker, Textarea, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardCalendarNew.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendarNew({Dialog, dialog, locale, create, setCreate, setRefresh, width, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [start, setStart] = useState(create.start ? create.start : '');
    const [end, setEnd] = useState(create.end ? create.end : '');
    const [color, setColor] = useState('');
    const [notes, setNotes] = useState('');

    const CloseDialog = useCallback(() => {
        setCreate({});
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        if (!title || !start || !end) {
            setToast({message: locale.dashboardCalendarNew.l, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardCalendarNew.m, color: 'info', display: 'bottom', duration: 1000});

            const message = {
                type: 'wss',
                path: 'calendar',
                action: 'put',
                data: {
                    title: title,
                    location: location,
                    start: dayjs(start).format(),
                    end: dayjs(end).format(),
                    color: color,
                    notes: notes
                }
            };
            wsCall(message);
        }
    }

    const Start = data => {
        setStart(data);
        if (!end) {
            setEnd(data);
        }
    }

    const End = data => {
        setEnd(data);
        if (!start) {
            setStart(data);
        }
    }

    const PutCalendar = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
            CloseDialog();
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'calendar') {
            PutCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutCalendar, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-new-popup" width={600} display="center" headerText={locale.dashboardCalendarNew.o} buttons={[{text:locale.dashboardCalendarNew.k, cssClass: 'cs-dashboard-calendar-new-pop-button', handler: () => Save()}]} isOpen={dialog.view === 'dashboardCalendarNew'} onClose={CloseDialog} >
            <Input inputStyle="underline" label={locale.dashboardCalendarNew.a} labelStyle="floating" type="text" name="title" value={title} onChange={(ev) => {setTitle(ev.target.value)}} />
            <mobiscroll.Color theme="ios" themeVariant="light" touchUi={false} value={color} onSet={(event) => setColor(event.value)}>
                <mobiscroll.Input theme="ios" themeVariant="light" inputStyle="underline" >
                    {locale.dashboardCalendarNew.e}
                </mobiscroll.Input>
            </mobiscroll.Color>
            <Input inputStyle="underline" label={locale.dashboardCalendarNew.b} labelStyle="floating" type="text" name="location" value={location} onChange={(ev) => {setLocation(ev.target.value)}} />
            <Datepicker
                controls={['date', 'time']}
                touchUi={width > 576 ? false : true}
                inputStyle="underline"
                label={locale.dashboardCalendarNew.c}
                labelStyle="floating"
                stepMinute={5}
                buttons={['set', 'cancel']}
                value={start}
                selectMultiple={false}
                onChange={(event) => Start(event.value)}
            />
            <Datepicker
                controls={['date', 'time']}
                touchUi={width > 576 ? false : true}
                inputStyle="underline"
                label={locale.dashboardCalendarNew.d}
                labelStyle="floating"
                stepMinute={5}
                buttons={['set', 'cancel']}
                value={end}
                selectMultiple={false}
                onChange={(event) => End(event.value)}
            />
            <div className="cs-dashboard-calendar-new-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardCalendarNew.f} value={notes} onChange={(ev) => setNotes(ev.target.value)} />
            </div>
        </Popup>
    );
}

export default DashboardCalendarNew;
