import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Input, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderTabNew.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderTabNew({locale, dialog, Dialog, tabs, setTabs, setMessages, setText, tab, Navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [name, setName] = useState('');

    const ClosePopup = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Name = () => {
        if (!name) {
            toast({message: locale.dashboardHeaderTabNew.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {
            const itemTab = {
                id: tab,
                name: ''
            }

            const matchingTab = tabs.find(data => data.id === tab);

            if (matchingTab) {
                itemTab.name = matchingTab.name;
            }

            const message = {
                type: 'wss',
                path: 'tab',
                action: 'put',
                data: {
                    name: name,
                    tabs: tabs
                }
            };
            wsCall(message);
        }
    }

    const PutTab = useCallback(data => {
        if (data.tabs) {
            setTabs(data.tabs);
        }
        if (data.tab) {
            setMessages([]);
            setText('<p></p>');
            window.localStorage.text = '<p></p>';

            Navigation({tab: data.tab});

            ClosePopup();
        }
    }, [ClosePopup, tabs, Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'tab') {
            PutTab(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutTab, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-tab-new-popup" width={600} display="center" closeOnOverlayClick={true} headerText={locale.dashboardHeaderTabNew.e} buttons={[{text: locale.dashboardHeaderTabNew.d, cssClass: 'cs-dashboard-header-tab-new-popup-save', handler: () => Name()}]} isOpen={dialog.view === 'dashboardHeaderTabNew'} onClose={ClosePopup}>
            <Input inputStyle="underline" label={locale.dashboardHeaderTabNew.b} labelStyle="floating" type="text" name="account" value={name} onChange={ev => {setName(ev.target.value) }} />
        </Popup>
    );
}

export default DashboardHeaderTabNew;
