import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, Input, Checkbox, Select, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderUser.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderUser({locale, dialog, Dialog, user, Logout, configuration, setUser, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [notifications, setNotifications] = useState(user.notifications);
    const [mobile, setMobile] = useState(user.id);
    const [voice, setVoice] = useState(user.voice);

    const CloseDialog = useCallback(() => {
        setPassword('');
        setConfirm('');
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        if ((password || confirm) && password !== confirm ) {
            setToast({message: locale.dashboardHeaderUser.a, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const item = {...user};
            item.notifications = notifications;
            item.voice = voice;
            setUser(item);

            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: password ? {password: password, notifications: notifications, voice: voice} : {notifications: notifications, voice: voice}
            };
            wsCall(message);
        }
    }

    const UpdateUser = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeaderUser.b, color: 'info', display: 'bottom', duration: 1000});
            CloseDialog();
        }
    }, [locale.dashboardHeaderUser.b, CloseDialog]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user') {
            UpdateUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUser, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-user-popup" width={600} display="center" headerText={locale.dashboardHeaderUser.c} buttons={[{text:locale.dashboardHeaderUser.e, cssClass: 'cs-dashboard-header-user-button',handler: () => Logout('all')}, {text:locale.dashboardHeaderUser.d, cssClass: 'cs-dashboard-header-user-button', handler: () => Save()}]} isOpen={dialog.view === 'dashboardHeaderUser'} onClose={CloseDialog}>
            <Input disabled inputStyle="underline" label={locale.dashboardHeaderUser.f} labelStyle="floating" type="tel" name="mobile" value={mobile} />
            <Checkbox
                color="info"
                label={locale.dashboardHeaderUser.i}
                checked={notifications}
                onChange={(ev) => setNotifications(ev.target.checked)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderUser.l}
                labelStyle="floating"
                touchUi={false}
                rows={configuration.mobi.voice.length}
                display="anchored"
                data={configuration.mobi.voice}
                value={voice}
                onChange={(event) => setVoice(event.value)}
            />
            <Input inputStyle="underline" label={locale.dashboardHeaderUser.g} labelStyle="floating" type="password" name="password" passwordToggle={true} value={password} onChange={(ev) => {setPassword(ev.target.value)}} />
            <Input inputStyle="underline" label={locale.dashboardHeaderUser.h} labelStyle="floating" type="password" name="confirm" passwordToggle={true} value={confirm} onChange={(ev) => {setConfirm(ev.target.value)}} />
            <Button variant="flat" color="secondary">
                {locale.dashboardHeaderUser.j} {user.sessions.length} {locale.dashboardHeaderUser.k}
            </Button>
        </Popup>
    );
}

export default DashboardHeaderUser;
