import React, {useCallback, useState} from 'react';
import {Popup, Input, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridColumnNew.css';
import {textEditor} from "react-data-grid";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridColumnNew({Dialog, dialog, locale, rows, setRows, columns, setColumns, setRefresh, id, setToast}) {
    const [name, setName] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Exists = data => {
        return columns.some(obj => obj.key === data);
    }

    const Convert = data => {
        return data.toLowerCase().replace(/\s/g, '');
    }

    const Save = () => {
        if (!name) {
            setToast({message: locale.dashboardGridColumnNew.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemName = Convert(name);

            if (Exists(itemName)) {
                setToast({message: locale.dashboardGridColumnNew.e, color: 'danger', display: 'bottom', duration: 3000});
            } else {
                const itemColumns = columns;
                itemColumns.push({
                    key: itemName,
                    name: name,
                    renderEditCell: textEditor
                })
                setColumns(itemColumns);

                const itemRows = rows;

                itemRows.forEach(obj => {
                    if (!obj.hasOwnProperty(itemName)) {
                        obj[itemName] = "";
                    }
                });
                setRows(itemRows);
                setRefresh(true);
                CloseDialog();
            }
        }
    }

    return (
        <Popup className="cs-dashboard-grid-column-new-popup" display="center" width="600" buttons={[{text:locale.dashboardGridColumnNew.d, cssClass: 'cs-dashboard-grid-column-new-pop-button', handler: () => Save()}]} headerText={locale.dashboardGridColumnNew.a} isOpen={dialog.view === ('dashboardGridColumnNew' + id.toString())} onClose={CloseDialog} >
            <Input inputStyle="underline" label={locale.dashboardGridColumnNew.b} labelStyle="floating" type="text" name="conversation" value={name} onChange={(ev) => {setName(ev.target.value)}} />
        </Popup>
    );
}

export default DashboardGridColumnNew;
