import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Popup, Textarea, Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardQuestionFiles.css';
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan, faFileImage, faFileCircleCheck, faFileCode, faFilePdf, faFilm, faFileWaveform, faFileZip, faFile} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import Truncate from "../utils/Truncate";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestionFiles({Dialog, dialog, locale, setToast, selectedFiles, setSelectedFiles, width, Vision}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const hiddenFileInput = useRef(null);
    const [file, setFile] = useState('');
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Delete = data => {
        confirm({
            title: locale.dashboardQuestionFiles.g,
            message: locale.dashboardQuestionFiles.h,
            okText: locale.dashboardQuestionFiles.i,
            cancelText: locale.dashboardQuestionFiles.j,
            callback: (res) => {
                if (res) {

                    const message = {
                        type: 'wss',
                        path: 'file',
                        action: 'delete',
                        data: {
                            file: data,
                            files: files
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Select = data => {
        if (!Vision) {
            setToast({message: locale.dashboardQuestionFiles.n, color: 'danger', display: 'bottom', duration: 3000});
        } else if (Vision && (data.type === 'image' || data.subType === 'pdf' || data.subType === 'json' || data.type === 'text')) {
            setSelectedFiles((prevArray) => {
                const existingIndex = prevArray.findIndex(item => item.id === data.id);

                if (existingIndex !== -1) {
                    return prevArray.filter((item) => item.id !== data.id);
                } else {
                    return [...prevArray, data];
                }
            });
        } else {
            setToast({message: locale.dashboardQuestionFiles.l, color: 'danger', display: 'bottom', duration: 3000});
        }
    }

    const Icon = data => {

        if (selectedFiles.some(item => item.id === data.id)) {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFileCircleCheck} />
        } else if (data.type === 'image') {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFileImage} />
        } else if (data.subType === 'html' || data.subType === 'css' || data.subType === 'javascript' || data.subType === 'json' || data.subType === 'xml') {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFileCode} />
        } else if (data.subType === 'pdf') {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFilePdf} />
        } else if (data.type === 'video') {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFilm} />
        } else if (data.type === 'audio') {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFileWaveform} />
        } else if (data.subType === 'zip') {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFileZip} />
        } else {
            return <FontAwesomeIcon className="cs-dashboard-question-files-list-icon" icon={faFile} />
        }
    }

    const Files = () => {
        const message = {
            type: 'wss',
            path: 'files',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const Paste = data => {
        data.preventDefault();
        const items = data.clipboardData.items;
        setToast({message: locale.dashboardQuestionFiles.d, color: 'info', display: 'bottom', duration: 1000});

        for (const item of items) {
            if (item.type.indexOf('image') !== -1) {
                const file = item.getAsFile();

                const reader = new FileReader();
                reader.onloadend = () => {
                    setToast({message: locale.dashboardQuestionFiles.d, color: 'info', display: 'bottom', duration: 1000});

                    const itemData = reader.result;
                    const itemName = file.name;
                    const itemType = file.type;

                    const message = {
                        type: 'wss',
                        path: 'file',
                        action: 'put',
                        data: {
                            file: itemData,
                            name: itemName,
                            type: itemType,
                            files: files
                        }
                    };
                    wsCall(message);
                };
                reader.readAsDataURL(file);
            }
        }
    }

    const Upload = data => {
        const file = data.target.files[0];
        setToast({message: locale.dashboardQuestionFiles.d, color: 'info', display: 'bottom', duration: 1000});

        const reader = new FileReader();
        reader.addEventListener('load', ()=>{
            const itemData = reader.result;
            const itemName = file.name;
            const itemType = file.type;

            const message = {
                type: 'wss',
                path: 'file',
                action: 'put',
                data: {
                    file: itemData,
                    name: itemName,
                    type: itemType,
                    files: files
                }
            };
            wsCall(message);
        })
        reader.readAsDataURL(file);
    }

    const Item = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-dashboard-question-files-list" onClick={(ev) => Select(data.item)}>
                        {Icon(data.item)}
                        <div className="cs-dashboard-question-files-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{width < 992 ? Truncate(data.item.name, 15) : Truncate(data.item.name, 30)}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-dashboard-question-files-item-txt">
                                {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-dashboard-question-files-list-button">
                    <Button color="primary" variant="flat" onClick={() => Delete(data.item.id)} >
                        <FontAwesomeIcon className="cs-dashboard-question-files-icon-right" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const PutFile = useCallback(data => {
        if (data.files) {
            setFiles(data.files);
            setFile('');
        }
    }, []);

    const GetFiles = useCallback(data => {
        if (data.files) {
            setFiles(data.files);
            setLoading(false);
        }
    }, []);

    const DeleteFile = useCallback(data => {
        if (data.files) {
            setFiles(data.files);
        }
    }, []);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'file') {
            DeleteFile(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteFile, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'files') {
            GetFiles(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetFiles, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'file') {
            PutFile(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutFile, setWsResponse]);

    useEffect(() => {
        Files();
    }, []);

    return (
        <Popup className="cs-dashboard-question-files-popup" closeOnOverlayClick={false} closeOnEsc={false} width={600} display="center" headerText={selectedFiles.length === 0 ? locale.dashboardQuestionFiles.b : locale.dashboardQuestionFiles.b + ' - ' + selectedFiles.length + ' ' + locale.dashboardQuestionFiles.k} buttons={[{text:locale.dashboardQuestionFiles.a, cssClass: 'cs-dashboard-question-files-pop-button', handler: 'close'}]} isOpen={dialog.view === 'dashboardQuestionFiles'} onClose={CloseDialog} >
            <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardQuestionFiles.c} value={file} onPaste={Paste} />
            <div className="mbsc-button-group-block cs-dashboard-question-files-block-button">
                <Button onClick={() => hiddenFileInput.current.click()} className="mbsc-bold cs-dashboard-question-files-button" >
                    {locale.dashboardQuestionFiles.f}
                </Button>
            </div>
            {!loading &&
                <div className="cs-dashboard-question-files-helper" >
                    <p>{(files && files.length === 0) ? locale.dashboardQuestionFiles.m : locale.dashboardQuestionFiles.e}</p>
                </div>
            }
            {loading &&
                <Loading />
            }
            {!loading && files && files.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={files}
                />
            }
            <div style={{display: 'none'}}>
                <input type="file" ref={hiddenFileInput} onChange={Upload} />
            </div>
        </Popup>
    );
}

export default DashboardQuestionFiles;
