import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Input, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderTabRename.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderTabRename({locale, dialog, Dialog, tab, tabs, setTabs}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [name, setName] = useState(tab.name);

    const ClosePopup = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Name = () => {
        if (!name) {
            toast({message: locale.dashboardHeaderTabRename.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {

            const message = {
                type: 'wss',
                path: 'tab-name',
                action: 'update',
                data: {
                    id: tab.id,
                    name: name,
                    tabs: tabs
                }
            };
            wsCall(message);
        }
    }

    const UpdateTabName = useCallback(data => {
        if (data.tabs) {
            setTabs(data.tabs);
            ClosePopup();
        }
    }, [ClosePopup]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'tab-name') {
            UpdateTabName(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateTabName, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-tab-rename-popup" width={600} display="center" closeOnOverlayClick={true} headerText={locale.dashboardHeaderTabRename.e} buttons={[{text: locale.dashboardHeaderTabRename.d, cssClass: 'cs-dashboard-header-tab-rename-popup-save', handler: () => Name()}]} isOpen={dialog.view === 'dashboardHeaderTabRename'} onClose={ClosePopup}>
            <Input inputStyle="underline" label={locale.dashboardHeaderTabRename.b} labelStyle="floating" type="text" name="account" value={name} onChange={ev => {setName(ev.target.value) }} />
        </Popup>
    );
}

export default DashboardHeaderTabRename;
