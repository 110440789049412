import React, {useCallback, useContext, useState} from 'react';
import {Popup, Input, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/headerAccount.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function HeaderAccount({locale, dialog, Dialog}) {
    const {restCall} = useContext(NetworkContext);
    const [account, setAccount] = useState('');

    const ClosePopup = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Configuration = () => {
        if (!account) {
            toast({message: locale.headerAccount.g, color: 'danger', display: 'bottom', duration: 3000});
        } else if (account) {
            let item = '';

            if (account.includes('-')) {
                item = account;
            } else {
                const itemDomain = window.location.hostname;
                const itemParts = itemDomain.split('.');
                const itemName = itemParts[itemParts.length - 2];

                item = itemName + '-' + account;
            }

            const message = {
                type: 'rest',
                path: 'account-configuration',
                action: 'get',
                data: {
                    account: item
                }
            };
            restCall(message);
        }
    }

    const Close = () => {
        if (window.localStorage.account) {
            return true;
        } else if (!window.localStorage.account) {
            return false;
        }
    }

    return (
        <Popup className="cs-header-account-popup" display="center" closeOnOverlayClick={Close()} closeOnEsc={Close()} headerText={locale.headerAccount.a} buttons={[{text: locale.headerAccount.f, cssClass: 'cs-header-account-popup-save', handler: () => Configuration()}]} isOpen={dialog.view === 'headerAccount'} onClose={ClosePopup}>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.b}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.c}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.d}</p>
            </div>
            <Input inputStyle="underline" label={locale.headerAccount.e} labelStyle="floating" type="text" name="account" value={account} onChange={ev => {setAccount(ev.target.value) }} />
        </Popup>
    );
}

export default HeaderAccount;
