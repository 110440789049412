import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderConversations.css';
import dayjs from "dayjs";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";
import Truncate from "../utils/Truncate";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderConversations({locale, dialog, Dialog, Conversation, setToast, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [conversations, setConversations] = useState([]);
    const [reload, setReload] = useState(false);

    const MenuItem = data => {
        const itemDate = dayjs(parseInt(data.item.timestamp)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.timestamp}>
            <div className="mbsc-bold mbsc-txt-muted">{width < 992 ? Truncate(data.item.text, 15) : Truncate(data.item.text, 30)}</div>
            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-dashboard-header-conversations-item-txt">
                {itemDate}
            </div>
        </li>;
    }

    const Open = data => {
        confirm({
            title: locale.dashboardHeaderConversations.d,
            message: locale.dashboardHeaderConversations.e,
            okText: locale.dashboardHeaderConversations.f,
            cancelText: locale.dashboardHeaderConversations.g,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'conversation-archive',
                        action: 'get',
                        data: {
                            conversation: conversations[data].timestamp
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const CloseDialog = useCallback(() => {
        setConversations([]);
        Dialog({view: 'close'});
    }, [Dialog]);

    const Conversations = useCallback(() => {
        const message = {
            type: 'wss',
            path: 'conversation-archives',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }, [wsCall]);

    const GetConversationArchives = useCallback(data => {
        if (data.conversations) {
            setConversations(data.conversations);
        }
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    const DeleteConversationArchive = useCallback(data => {
        if (data.toast) {
            setReload(true);
            setToast({message: locale.dashboardHeaderConversations.a, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardHeaderConversations.a]);

    const GetConversationArchive = useCallback(data => {
        if (data.toast) {
            Conversation();
            CloseDialog();
        }
    }, [Conversation, CloseDialog]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'conversation-archives') {
            GetConversationArchives(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetConversationArchives, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'conversation-archive') {
            DeleteConversationArchive(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteConversationArchive, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'conversation-archive') {
            GetConversationArchive(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetConversationArchive, setWsResponse]);

    useEffect(() => {
        if (reload) {
            Conversations();
        }
    }, [reload, Conversations]);

    useEffect(() => {
        Conversations();
    }, [])

    return (
        <Popup className="cs-dashboard-header-conversations-popup" scrollLock={false} display="center" fullScreen={true} maxHeight="700" headerText={locale.dashboardHeaderConversations.b} buttons={[{text:locale.dashboardHeaderConversations.j, cssClass: 'cs-dashboard-header-conversations-pop-button', handler: 'close'}]} isOpen={dialog.view === 'dashboardHeaderConversations'} onClose={CloseDialog}>
            {!reload &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={MenuItem}
                    stages={{
                        right: [{
                            percent: -25,
                            color: 'red',
                            text: locale.dashboardHeaderConversations.c,
                            confirm: true,
                            action: (event, inst) => {
                                confirm({
                                    title: locale.dashboardHeaderConversations.h,
                                    message: locale.dashboardHeaderConversations.i,
                                    okText: locale.dashboardHeaderConversations.c,
                                    cancelText: locale.dashboardHeaderConversations.g,
                                    callback: (res) => {
                                        if (res) {
                                            const itemConvo = conversations[event.index].timestamp;

                                            const message = {
                                                type: 'wss',
                                                path: 'conversation-archive',
                                                action: 'delete',
                                                data: {
                                                    timestamp: itemConvo
                                                }
                                            };
                                            wsCall(message);
                                        } else {
                                            inst.close(event.target);
                                        }
                                    }
                                });
                                return false;
                            }
                        }]
                    }}
                    data={conversations}
                    onItemTap={event => Open(event.index)}
                />
            }
        </Popup>
    );
}

export default DashboardHeaderConversations;
