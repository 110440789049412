import React, {useCallback, useEffect, useState} from 'react';
import {Popup, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridColumnDelete.css';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridColumnDelete({Dialog, dialog, locale, columns, setColumns, setRefresh, rows, setRows, id, setToast}) {
    const [name, setName] = useState('');
    const [columnData, setColumnData] = useState([]);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Delete = () => {
        if (!name) {
            setToast({message: locale.dashboardGridColumnDelete.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            let itemColumns = columns;
            itemColumns = itemColumns.filter(function(item){
                return item.key !== name;
            })
            setColumns(itemColumns);

            const itemRows = rows;
            itemRows.forEach(obj => {
                if (obj.hasOwnProperty(name)) {
                    delete obj[name];
                }
            });
            setRows(itemRows);
            setRefresh(true);
            CloseDialog();
        }
    }

    useEffect(() => {
        if (columns.length >= 1) {
            const itemColumns = [];
            columns.map(function(item){
                if (item.name) {
                    itemColumns.push({
                        value: item.key,
                        text: item.name
                    })
                }
                return item
            })
            setColumnData(itemColumns);
        }
    }, []);

    return (
        <Popup className="cs-dashboard-grid-column-delete-popup" display="center" width="600" buttons={[{text:locale.dashboardGridColumnDelete.d, cssClass: 'cs-dashboard-grid-column-delete-pop-button', handler: () => Delete()}]} headerText={locale.dashboardGridColumnDelete.a} isOpen={dialog.view === ('dashboardGridColumnDelete' + id.toString())} onClose={CloseDialog} >
            <div className="cs-dashboard-grid-column-delete-helper">
                <p>{locale.dashboardGridColumnDelete.e}</p>
            </div>
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardGridColumnDelete.b}
                labelStyle="floating"
                rows={columnData.length}
                display="anchored"
                touchUi={false}
                data={columnData}
                selectMultiple={false}
                value={name}
                onChange={(event) => setName(event.value)}
            />
        </Popup>
    );
}

export default DashboardGridColumnDelete;
