import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderCalendar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay, faCalendarDays, faCalendarWeek} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderCalendar({Dialog, dialog, locale, Navigation, navigation}) {
    const [refresh, setRefresh] = useState({});
    const menu = useRef([{day: true}, {week: true}, {month: true}]);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const MenuItem = data => {
        let item = '';

        if (data.item.day) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-calendar-li">
                <Button disabled={navigation.card === 'calendar' && navigation.tab === 'day'} color="primary" variant="flat" className="cs-dashboard-header-calendar-button mbsc-bold" onClick={() => Calendar({card: 'calendar', tab: 'day'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-calendar-icon" icon={faCalendarDay} /> {locale.dashboardHeaderCalendar.a}
                </Button>
            </li>;
        } else if (data.item.week) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-calendar-li">
                <Button disabled={navigation.card === 'calendar' && navigation.tab === 'week'} color="primary" variant="flat" className="cs-dashboard-header-calendar-button mbsc-bold" onClick={() => Calendar({card: 'calendar', tab: 'week'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-calendar-icon" icon={faCalendarWeek} /> {locale.dashboardHeaderCalendar.b}
                </Button>
            </li>;
        } else if (data.item.month) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-calendar-li">
                <Button disabled={navigation.card === 'calendar' && navigation.tab === 'month'} color="primary" variant="flat" className="cs-dashboard-header-calendar-button mbsc-bold" onClick={() => Calendar({card: 'calendar', tab: 'month'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-calendar-icon" icon={faCalendarDays} /> {locale.dashboardHeaderCalendar.c}
                </Button>
            </li>;
        }

        return item;
    }

    const Calendar = data => {
        if (navigation.card === 'calendar') {
            Navigation({card: 'close'});
            setRefresh(data);
        } else {
            Navigation(data);
            CloseDialog();
        }
    }

    useEffect(() => {
        if (!Empty(refresh)) {
            Navigation(refresh);
            CloseDialog();
        }
    }, [refresh, Navigation, CloseDialog]);

    return (
        <Popup className="cs-dashboard-header-calendar-popup" display="anchored" anchor={document.getElementById('dashboardHeaderCalendar')} buttons={[]} isOpen={dialog.view === 'dashboardHeaderCalendar'} onClose={CloseDialog} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={MenuItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardHeaderCalendar;
