import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Popup, Input, Datepicker, Checkbox, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '../styles/dashboardTodosTodo.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardTodosTodo({Dialog, dialog, todo, locale, setRefresh, width, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [item, setItem] = useState(todo.item);
    const [list, setList] = useState(todo.list);
    const [completed, setCompleted] = useState(todo.completed);
    const [due, setDue] = useState(todo.due);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        if (!item) {
            setToast({message: locale.dashboardTodosTodo.e, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardTodosTodo.f, color: 'info', display: 'bottom', duration: 1000});
            if (due) {
                setDue(dayjs(due).format());
            }

            const message = {
                type: 'wss',
                path: 'todo',
                action: 'update',
                data: {
                    todo: todo,
                    item: item,
                    completed: completed,
                    due: due,
                    list: list
                }
            };
            wsCall(message);
        }
    }

    const Delete = () => {
        confirm({
            title: locale.dashboardTodosTodo.h,
            message: locale.dashboardTodosTodo.i,
            okText: locale.dashboardTodosTodo.d,
            cancelText: locale.dashboardTodosTodo.j,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'todo',
                        action: 'delete',
                        data: {
                            todo: todo
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const UpdateTodo = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
            CloseDialog();
        }
    }, [CloseDialog]);

    const DeleteTodo = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
            CloseDialog();
        }
    }, [CloseDialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'todo') {
            DeleteTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteTodo, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'todo') {
            UpdateTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateTodo, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-event-popup" width={600} display="center" headerText={locale.dashboardTodosTodo.a} buttons={[{text:locale.dashboardTodosTodo.d, cssClass: 'cs-dashboard-todos-todo-pop-button',handler: () => Delete()}, {text:locale.dashboardTodosTodo.b, cssClass: 'cs-dashboard-todos-todo-pop-button', handler: () => Save()}]} isOpen={dialog.view === 'dashboardTodosTodo'} onClose={CloseDialog} >
            <Input inputStyle="underline" label={locale.dashboardTodosTodo.k} labelStyle="floating" type="text" name="list" value={list} onChange={(ev) => setList(ev.target.value)} />
            <Input inputStyle="underline" label={locale.dashboardTodosTodo.a} labelStyle="floating" type="text" name="title" value={item} onChange={(ev) => setItem(ev.target.value)} />
            <Datepicker
                controls={['date']}
                touchUi={width > 576 ? false : true}
                inputStyle="underline"
                label={locale.dashboardTodosTodo.c}
                labelStyle="floating"
                buttons={['set', 'cancel']}
                value={due}
                selectMultiple={false}
                onChange={(event) => setDue(event.value)}
            />
            <Checkbox
                color="info"
                label={locale.dashboardTodosTodo.g}
                checked={completed}
                onChange={(ev) => setCompleted(ev.target.checked)}
            />
        </Popup>
    );
}

export default DashboardTodosTodo;
