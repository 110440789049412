import React, {useCallback} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardInfo.css';
import Empty from '../utils/Empty';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardInfo({Dialog, dialog, messageData, setMessageData, messageAnchor, configuration, setMessageAnchor}) {

    const CloseDialog = useCallback(() => {
        setMessageData({});
        setMessageAnchor({});
        Dialog({view: 'close'});
    }, [Dialog]);

    const Model = data => {
        const matchingItem = configuration.mobi.model.find(item => item.value === data);
        return matchingItem ? matchingItem.short : '';
    }

    const Functions = data => {
        let item = '';
        if (data && data.length !== 0) {
            data.forEach(itemData => {
                configuration.mobi.functions.forEach(itemFunctions => {
                    if (itemFunctions.value === itemData && item.length === 0) {
                        item = itemFunctions.short;
                    } else if (itemFunctions.value === itemData && item.length !== 0) {
                        item = item + ', ' + itemFunctions.short;
                    }
                })
            })
        }
        return item;
    }

    return (
        <Popup anchor={!Empty(messageAnchor) ? messageAnchor : document.getElementById('dashboardInfo')} display="anchored" buttons={[]} isOpen={dialog.view === 'dashboardInfo'} onClose={CloseDialog}>
            <div className="mbsc-grid">
                {messageData.model &&
                    <div className="mbsc-row">
                        <Button color="secondary" variant="flat" className="mbsc-bold" >
                            {Model(messageData.model)}
                        </Button>
                    </div>
                }
                {messageData.functions && !(messageData.model.includes('claude') || messageData.model.includes('Claude')) &&
                    <div className="mbsc-row">
                        <Button color="secondary" variant="flat" className="mbsc-bold" >
                            {Functions(messageData.functions)}
                        </Button>
                    </div>
                }
                {messageData.context && messageData.usage &&
                    <div className="mbsc-row">
                        <Button color="secondary" variant="flat" className="mbsc-bold" >
                            {messageData.usage.total_tokens} of {messageData.context} tokens
                        </Button>
                    </div>
                }
            </div>
        </Popup>
    );
}

export default DashboardInfo;
