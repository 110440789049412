import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Popup, Segmented, SegmentedGroup, Input, Datepicker, Checkbox, Textarea, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '../styles/dashboardCalendarEvent.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendarEvent({Dialog, dialog, event, locale, setRefresh, width, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [title, setTitle] = useState(event.title);
    const [location, setLocation] = useState(event.location);
    const [start, setStart] = useState(event.start);
    const [end, setEnd] = useState(event.end);
    const [color, setColor] = useState(event.color);
    const [notes, setNotes] = useState(event.notes);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        if (!title || !start || !end) {
            setToast({message: locale.dashboardCalendarEvent.m, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardCalendarEvent.n, color: 'info', display: 'bottom', duration: 1000});

            const message = {
                type: 'wss',
                path: 'calendar',
                action: 'update',
                data: {
                    event: event,
                    title: title,
                    location: location,
                    start: dayjs(start).format(),
                    end: dayjs(end).format(),
                    color: color,
                    notes: notes
                }
            };
            wsCall(message);
        }
    }

    const Delete = () => {
        confirm({
            title: locale.dashboardCalendarEvent.o,
            message: locale.dashboardCalendarEvent.p,
            okText: locale.dashboardCalendarEvent.l,
            cancelText: locale.dashboardCalendarEvent.q,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'calendar',
                        action: 'delete',
                        data: {
                            event: event
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Tabs = data => {
        Dialog({tab: data.target.value});
    }

    const UpdateCalendar = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
            CloseDialog();
        }
    }, [CloseDialog]);

    const DeleteCalendar = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
            CloseDialog();
        }
    }, [CloseDialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'calendar') {
            DeleteCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteCalendar, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'calendar') {
            UpdateCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateCalendar, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-event-popup" width={600} display="center" headerText={title} buttons={[{text:locale.dashboardCalendarEvent.l, cssClass: 'cs-dashboard-calendar-event-pop-button', handler: () => Delete()}, {text:locale.dashboardCalendarEvent.k, cssClass: 'cs-dashboard-calendar-event-pop-button', handler: () => Save()}]} isOpen={dialog.view === 'dashboardCalendarEvent'} onClose={CloseDialog} >
            <SegmentedGroup className="cs-dashboard-calendar-event-nav" name="dashboardCalendarEvent" color="primary" value={dialog.tab} onChange={Tabs}>
                <Segmented value="event" >
                    {locale.dashboardCalendarEvent.i}
                </Segmented>
                <Segmented value="settings" >
                    {locale.dashboardCalendarEvent.j}
                </Segmented>
            </SegmentedGroup>
            {dialog.tab === 'event' &&
                <>
                    <Input className="cs-dashboard-calendar-event-input" inputStyle="underline" label={locale.dashboardCalendarEvent.a} labelStyle="floating" type="text" name="title" value={title} onChange={(ev) => setTitle(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.dashboardCalendarEvent.b} labelStyle="floating" type="text" name="location" value={location} onChange={(ev) => setLocation(ev.target.value)} />
                    <Datepicker
                        controls={['date', 'time']}
                        touchUi={width > 576 ? false : true}
                        inputStyle="underline"
                        label={locale.dashboardCalendarEvent.c}
                        labelStyle="floating"
                        stepMinute={5}
                        buttons={['set', 'cancel']}
                        value={start}
                        selectMultiple={false}
                        onChange={(event) => setStart(event.value)}
                    />
                    <Datepicker
                        controls={['date', 'time']}
                        touchUi={width > 576 ? false : true}
                        inputStyle="underline"
                        label={locale.dashboardCalendarEvent.d}
                        labelStyle="floating"
                        stepMinute={5}
                        buttons={['set', 'cancel']}
                        value={end}
                        selectMultiple={false}
                        onChange={(event) => setEnd(event.value)}
                    />
                    <div className="cs-dashboard-calendar-event-textarea">
                        <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardCalendarEvent.f} value={notes} onChange={(ev) => setNotes(ev.target.value)} />
                    </div>
                </>
            }
            {dialog.tab === 'settings' &&
                <>
                    <Checkbox
                        color="info"
                        label={locale.dashboardCalendarEvent.g}
                        checked={event.allDay}
                        disabled
                        className="cs-dashboard-calendar-event-input"
                    />
                    <mobiscroll.Color touchUi={false} value={color} onSet={(event) => setColor(event.value)}>
                        <mobiscroll.Input inputStyle="underline" >
                            {locale.dashboardCalendarEvent.e}
                        </mobiscroll.Input>
                    </mobiscroll.Color>
                    <Input disabled inputStyle="underline" label={locale.dashboardCalendarEvent.h} labelStyle="floating" type="text" name="recurring" value={event.recurring} />
                </>

            }
        </Popup>
    );
}

export default DashboardCalendarEvent;
