import React, {useState, useContext} from 'react';
import {Popup, Input, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginReset.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginReset({locale, dialog, Dialog, reset, mobile}) {
    const {restCall} = useContext(NetworkContext);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const CloseDialog = () => {
        setPassword('');
        setConfirm('');
        Dialog({view: 'close'});
    }

    const Reset = () => {
        if (!password || !confirm) {
            toast({message: locale.loginReset.a, color: 'danger', display: 'bottom', duration: 3000});
        } else if (password !== confirm) {
            toast({message: locale.loginReset.b, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const message = {
                type: 'rest',
                path: 'token',
                action: 'get',
                data: {
                    mobile: mobile,
                    password: password,
                    reset: reset
                }
            };
            restCall(message);
        }
    }

    return (
        <Popup className="cs-login-reset-popup" width={600} display="center" headerText={locale.loginReset.c} buttons={[{text: locale.loginReset.f, cssClass: 'cs-login-reset-save', handler: ()=> Reset()}]} isOpen={dialog.view === 'loginReset'} onClose={CloseDialog}>
            <Input inputStyle="underline" label={locale.loginReset.d} labelStyle="floating" type="password" name="password" passwordToggle={true} value={password} onChange={(ev) => setPassword(ev.target.value)} />
            <Input inputStyle="underline" label={locale.loginReset.e} labelStyle="floating" type="password" name="confirm" passwordToggle={true} value={confirm} onChange={(ev) => setConfirm(ev.target.value)} />
        </Popup>
    );
}

export default LoginReset;
