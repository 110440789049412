import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Textarea, Button, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderDalle.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderDalle({Dialog, dialog, locale, configuration, setToast, toast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [image, setImage] = useState('');
    const [prompt, setPrompt] = useState('');
    const [resolution, setResolution] = useState(configuration.mobi.dalle.resolution[0].value);
    const [quality, setQuality] = useState(configuration.mobi.dalle.quality[0].value);
    const [style, setStyle] = useState(configuration.mobi.dalle.style[0].value);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Generate = () => {
        setToast({message: locale.dashboardHeaderDalle.g, color: 'primary', display: 'center', duration: false});
        setImage('');

        const message = {
            type: 'wss',
            path: 'dalle',
            action: 'get',
            data: {
                prompt: prompt,
                resolution: resolution,
                quality: quality,
                style: style
            }
        };
        wsCall(message);
    }

    const Close = () => {
        CloseDialog();
    }

    const Open = () => {
        window.open(image, '_blank');
    }

    const GetDalle = useCallback(data => {
        if (data.image) {
            setImage(data.image);
        }
        if (data.error) {
            setToast({message: data.error, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'dalle') {
            GetDalle(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, GetDalle, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-dalle-popup" maxHeight={750} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" width="600" headerText={locale.dashboardHeaderDalle.c} buttons={[{text:locale.dashboardHeaderDalle.a, cssClass: 'cs-dashboard-header-dalle-pop-button', handler: () => Close()}]} isOpen={dialog.view === 'dashboardHeaderDalle'} onClose={CloseDialog} >
            <div className="cs-dashboard-header-dalle-helper">
                <p>{locale.dashboardHeaderDalle.e}</p>
            </div>
            <div className="cs-dashboard-header-dalle-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardHeaderDalle.d} value={prompt} onChange={(ev) => setPrompt(ev.target.value)} />
            </div>
            <div className="mbsc-button-group-block cs-dashboard-header-dalle-block-button">
                <Button disabled={!Empty(toast)} onClick={() => Generate()} className="mbsc-bold cs-dashboard-header-dalle-button" >
                    {locale.dashboardHeaderDalle.b}
                </Button>
            </div>
            {image &&
                <>
                    <div style={{backgroundImage: `url(${image})`}} className="cs-dashboard-header-dalle-image"  />
                    <div className="mbsc-button-group-block cs-dashboard-header-dalle-block-button">
                        <Button onClick={() => Open()} className="mbsc-bold cs-dashboard-header-dalle-button" >
                            {locale.dashboardHeaderDalle.h}
                        </Button>
                    </div>
                </>
            }
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderDalle.f}
                labelStyle="floating"
                rows={configuration.mobi.dalle.resolution.length}
                display="anchored"
                touchUi={false}
                data={configuration.mobi.dalle.resolution}
                value={resolution}
                onChange={(event) => setResolution(event.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderDalle.i}
                labelStyle="floating"
                rows={configuration.mobi.dalle.quality.length}
                display="anchored"
                touchUi={false}
                data={configuration.mobi.dalle.quality}
                value={quality}
                onChange={(event) => setQuality(event.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderDalle.j}
                labelStyle="floating"
                rows={configuration.mobi.dalle.style.length}
                display="anchored"
                touchUi={false}
                data={configuration.mobi.dalle.style}
                value={style}
                onChange={(event) => setStyle(event.value)}
            />
        </Popup>
    );
}

export default DashboardHeaderDalle;
